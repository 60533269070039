import React from 'react'
import { CircularProgress } from '@material-ui/core'
import ReactDOM from 'react-dom'
import scriptLoader from 'react-async-script-loader'
import config from '../config'

//create button here
let PayPalButton = null

class PaypalButton extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true
    }

    window.React = React
    window.ReactDOM = ReactDOM
  }

  componentDidMount() {
    const { isScriptLoaded, isScriptLoadSucceed } = this.props

    if (isScriptLoaded && isScriptLoadSucceed) {
      PayPalButton = window.paypal.Buttons.driver("react", { React, ReactDOM })
      this.setState({ loading: false })
    }
  }

  componentWillReceiveProps(nextProps) {
    const { isScriptLoaded, isScriptLoadSucceed } = nextProps

    const scriptJustLoaded = !this.state.showButtons && !this.props.isScriptLoaded && isScriptLoaded

    if (scriptJustLoaded) {
      if (isScriptLoadSucceed) {
        PayPalButton = window.paypal.Buttons.driver("react", {
          React,
          ReactDOM
        })
        this.setState({ loading: false })
      }
    }
  }

  createOrder = (data, actions) => {
    this.props.onPaymentStart()

    return actions.order.create({
      intent: 'CAPTURE',
      purchase_units: [
        {
          description: this.props.name,
          amount: {
            currency_code: "EUR",
            value: this.props.value
          }
        }
      ]
    })
  }

  onApprove = (data, actions) => {
    actions.order.capture().then(details => {
      this.props.onPaymentCompleted(details)
    })
  }

  onError = (err) => {
    console.log(err)
    this.props.onPaymentError()
  }

  render() {
    if (this.state.loading) { return null }

    return (
      <div className="main">
        <PayPalButton
          createOrder={(data, actions) => this.createOrder(data, actions)}
          onApprove={(data, actions) => this.onApprove(data, actions)}
          onError={(err) => this.onError(err)}
        />
      </div>
    );
  }
}

export default scriptLoader(`https://www.paypal.com/sdk/js?client-id=${config.paypalClientId}&currency=EUR&intent=capture&disable-funding=venmo,sepa,bancontact,eps,giropay,ideal,mybank,p24,sofort`)(PaypalButton)
