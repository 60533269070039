import config from '../config'
import { storeGet } from './store'

export function getGenders() {
  return getRequest('/get_genders', {})
}

export function getProfessions() {
  return getRequest('/get_professions', {})
}

export function getHobbies() {
  return getRequest('/get_hobbies', {})
}

export function getMoney(userId) {
  return postRequest('/get_money', { user_id: userId })
}

export function modifyUser(params) {
  return postRequest('/modify_user', params)
}

export function createUser(params) {
  return postRequest('/create_user', params)
}

export function login(params) {
  return postRequest('/login', params)
}

export function restoreUserPassword(params) {
  return postRequest('/restore_user_password', params)
}

export function getErogPos(params) {
  return postRequest('/get_erog_pos', params)
}

export function getUserBottles(userId) {
  return postRequest('/get_user_bottles', { user_id: userId })
}

export function notifyBottleInitialization(params) {
  return postRequest('/notify_bottle_initialization', params)
}

export function getBottleSizes() {
  return postRequest('/get_bottle_sizes', {})
}

export function getLiquidTypes() {
  return postRequest('/get_liquid_types', {})
}

export function getPayPaypalEnv() {
  return postRequest('/pay/get_config_environment', {})
}

export function getPayPaypalClient() {
  return postRequest('/pay/get_paypal_client', {})
}

export function addPayPaypalProof(params) {
  return postRequest('/pay/add_proof', params)
}

export function getPayPaypalPendingProofs(params) {
  return postRequest('/pay/pending_proofs', params)
}

export function voidBottle(userId, bottleId) {
  return postRequest('/void_bottle', { user_id: userId, bottle_id: bottleId })
}

export function setBottle(userId, bottleId, contentType) {
  return postRequest('/set_bottle', { user_id: userId, ownerId: userId, bottle_id: bottleId, cont_type: contentType })
}

// GENERAL REQUEST
// ----------------------------------------------------------------------------------------

/**
 * @function postRequest
 * @param {*} path 
 * @param {*} params 
 */
export function postRequest(path, params) {
  const token = storeGet('token')
  params.path = path
  params.auth = token ? token : ''

  return new Promise((resolve, reject) => {
    let url = config.proxyUrl
    const formData = new FormData()
    Object.keys(params).map((k) => { formData.append(k, params[k]); return k })
    fetch(url, { method: "post", headers: { 'Accept': 'application/json' }, body: formData })
    .then((response) => response.json())
    .then((response) => {
      resolve(response)
    })
    .catch((err) => {
      console.log(err)
      reject(err)
    })
  })
}

/**
 * @function getRequest
 * @param {*} path 
 * @param {*} params 
 */
export function getRequest(path, params) {
  const token = storeGet('token')
  params.path = path
  params.auth = token ? token : ''

  return new Promise((resolve, reject) => {
    let url = config.proxyUrl + '?'
    Object.keys(params).map((key) => { url = `${url}${key}=${params[key]}&`; return key })
    fetch(url, { method: "get", headers: { 'Accept': 'application/json' } })
    .then((response) => response.json())
    .then((response) => {
      resolve(response)
    })
    .catch((err) => {
      console.log(err)
      reject(err)
    })
  })
}
