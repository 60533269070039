import React from 'react'
import { MobileStepper, Typography, FormControl, Input, InputLabel, Select, MenuItem, Button, TextField } from '@material-ui/core'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import CardComponent from '../components/CardComponent'
import { withGlobalContext } from '../contexts/GlobalContext'
import { onChangeFormInput } from '../libs/helpers'
import { getGenders, getProfessions, getHobbies, createUser } from '../libs/apis'

class SignupView extends React.Component {

  constructor(props) {
    super(props)

    this.state = { step: 0, _form: {}, _formGenders: [], _formProfessions: [], _formHobbies: [], _formSubmitting: false }
    this.maxSteps = 2
  }

  componentDidMount() {
    getGenders().then((response) => {
      this.setState({ _formGenders: response.data })
    }).catch(() => {})
    getProfessions().then((response) => {
      this.setState({ _formProfessions: response.data })
    }).catch(() => {})
    getHobbies().then((response) => {
      this.setState({ _formHobbies: response.data })
    }).catch(() => {})
  }

  goToLogin = () => {
    this.props.globalProvider.actions.setView('Login')
  }

  goToNextStep = () => {
    if (this.state._form.password !== this.state._form.passwordConfirmation) {
      this.setState({ _formMessage: this.props.globalProvider.t('Le due password non coincidono') })
      return
    }

    if (!this.state._form.email || !this.state._form.name || !this.state._form.surname || !this.state._form.password) {
      this.setState({ _formMessage: this.props.globalProvider.t('Compila tutti i campi per proseguire') })
      return
    }

    this.setState({ step: this.state.step + 1 })
  }

  goToPrevStep = () => {
    this.setState({ step: this.state.step - 1 })
  }

  onChangeFormInput = (e) => {
    onChangeFormInput(e, this)
  }

  onSubmitForm = (e) => {
    e.preventDefault()
    
    this.setState({ _formSubmitting: true }, () => {
      createUser(Object.assign({ language: 'it' }, this.state._form)).then((response) => {
        this.setState({ _formSubmitting: false })
        if (response.auth) {
          this.props.globalProvider.actions.login(response, () => {
            this.props.globalProvider.actions.setView('Dashboard')
          })
        }
      })
    })
  }

  render() {
    const { t } = this.props.globalProvider

    return (
      <>
        <CardComponent>
          <Typography gutterBottom variant="h5" component="h2">
            {t('Registrazione')}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {t('Compila i campi richiesti per registrarti. Se hai già un account puoi')} <a href="#" onClick={this.goToLogin}>{t('fare il login')}</a>.
          </Typography>

          <form style={{ marginTop: 50 }} onSubmit={this.onSubmitForm}>
            {this.state.step === 0 && (
              <>
                <FormControl fullWidth>
                  <InputLabel htmlFor="nameInput" shrink>{t('Nome')}</InputLabel>
                  <Input id="nameInput" name="name" onChange={this.onChangeFormInput} value={this.state._form.name} required />
                </FormControl>

                <FormControl fullWidth style={{ marginTop: 20 }}>
                  <InputLabel htmlFor="surnameInput" shrink>{t('Cognome')}</InputLabel>
                  <Input id="surnameInput" name="surname" onChange={this.onChangeFormInput} value={this.state._form.surname} required />
                </FormControl>

                <FormControl fullWidth style={{ marginTop: 20 }}>
                  <InputLabel htmlFor="emailInput" shrink>{t('Indirizzo email')}</InputLabel>
                  <Input id="emailInput" name="email" type="email" onChange={this.onChangeFormInput} value={this.state._form.email} required />
                </FormControl>

                <FormControl fullWidth style={{ marginTop: 20 }}>
                  <InputLabel htmlFor="passwordInput" shrink>{t('Password')}</InputLabel>
                  <Input id="passwordInput" name="password" type="password" onChange={this.onChangeFormInput} value={this.state._form.password} required />
                </FormControl>

                <FormControl fullWidth style={{ marginTop: 20 }}>
                  <InputLabel htmlFor="passwordConfirmationInput" shrink>{t('Conferma la password')}</InputLabel>
                  <Input id="passwordConfirmationInput" name="passwordConfirmation" type="password" onChange={this.onChangeFormInput} value={this.state._form.passwordConfirmation} required />
                </FormControl>
              </>
            )}
            {this.state.step === 1 && (
              <>
                <FormControl fullWidth>
                  <TextField label={t('Data di nascita')} type="date" name="year" onChange={this.onChangeFormInput} value={this.state._form.year} InputLabelProps={{ shrink: true }} required />
                </FormControl>

                <FormControl fullWidth style={{ marginTop: 20 }}>
                  <InputLabel id="sexInputLabel" htmlFor="sexInput" shrink>{t('Sesso')}</InputLabel>
                  <Select
                    labelId="sexInputLabel"
                    id="sexInput"
                    name="gender"
                    value={this.state._form.gender}
                    onChange={this.onChangeFormInput}
                    required
                  >
                    {this.state._formGenders.map((g) => {
                      return <MenuItem value={g.id} key={g.id}>{g.name}</MenuItem>
                    })}
                  </Select>
                </FormControl>

                <FormControl fullWidth style={{ marginTop: 20 }}>
                  <InputLabel id="professionInputLabel" htmlFor="professionInput" shrink>{t('Professione')}</InputLabel>
                  <Select
                    labelId="professionInputLabel"
                    id="professionInput"
                    name="occupation"
                    value={this.state._form.occupation}
                    onChange={this.onChangeFormInput}
                    required
                  >
                    {this.state._formProfessions.map((g) => {
                      return <MenuItem value={g.id} key={g.id}>{g.name}</MenuItem>
                    })}
                  </Select>
                </FormControl>

                <FormControl fullWidth style={{ marginTop: 20 }}>
                  <InputLabel id="hobbyInputLabel" htmlFor="hobbyInput" shrink>{t('Hobby')}</InputLabel>
                  <Select
                    labelId="hobbyInputLabel"
                    id="hobbyInput"
                    name="hobbies"
                    value={this.state._form.hobbies}
                    onChange={this.onChangeFormInput}
                    required
                  >
                    {this.state._formHobbies.map((g) => {
                      return <MenuItem value={g.id} key={g.id}>{g.name}</MenuItem>
                    })}
                  </Select>
                </FormControl>

                <Button variant="contained" color="primary" style={{ marginTop: 30 }} type="submit" disabled={this.state._formSubmitting} fullWidth>
                  {t('Conferma')}
                </Button>
              </>
            )}
          </form>

          <MobileStepper
            steps={this.maxSteps}
            style={{ marginTop: 30 }}
            position="static"
            activeStep={this.state.step}
            nextButton={
              <Button size="small" onClick={this.goToNextStep} disabled={this.state.step === this.maxSteps - 1}>
                {t('Avanti')}
                <KeyboardArrowRight />
              </Button>
            }
            backButton={
              <Button size="small" onClick={this.goToPrevStep} disabled={this.state.step === 0}>
                <KeyboardArrowLeft />
                {t('Indietro')}
              </Button>
            }
          />
        </CardComponent>

        <Typography variant="caption" component="div" paragraph style={{ marginTop: 30, textAlign: 'center' }}>
          {t('Oppure')}
        </Typography>

        <Button variant="outlined" color="primary" onClick={this.goToLogin} fullWidth>
          {t('Vai al login')}
        </Button>
      </>
    )
  }
}

export default withGlobalContext(SignupView)