import React from 'react'
import { Typography, CardHeader, CardContent, CardActions, Button, CircularProgress } from '@material-ui/core'
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn'
import SmartphoneIcon from '@material-ui/icons/Smartphone'
import CardComponent from '../components/CardComponent'
import { withGlobalContext } from '../contexts/GlobalContext'
import { printMoney } from '../libs/helpers'
import { getMoney } from '../libs/apis'
import config from '../config'

class DashboardView extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      loading: true
    }
  }

  componentDidMount() {
    getMoney(this.props.globalProvider.state.user.user_id).then((response) => {
      if (response.money) {
        this.props.globalProvider.actions.setUserMoney(response.money)
        this.setState({ loading: false })
      }
    })

    this.moneyInterval = setInterval(() => {
      getMoney(this.props.globalProvider.state.user.user_id).then((response) => {
        if (response.money) {
          this.props.globalProvider.actions.setUserMoney(response.money)
        }
      })
    }, 5000);
  }

  componentWillUnmount() {
    clearInterval(this.moneyInterval)
  }

  chromeWebAppPromptRun = () => {
    window.chromeWebAppPrompt.prompt()
    window.chromeWebAppPrompt.userChoice.then((choiceResult) => {
      window.chromeWebAppPrompt = null
    })
  }

  goToPaymentsNew = () => {
    this.props.globalProvider.actions.setView('PaymentsNew')
  }

  render() {
    const { t } = this.props.globalProvider

    if (!this.props.globalProvider.state.token) return null

    if (this.state.loading) {
      return (
        <div style={{ width: '100%', height: config.viewHeightWithoutTopBottomBar, textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress color="inherit" />
        </div>
      )
    }

    return (
      <>
        <CardComponent>
          <Typography gutterBottom variant="h5" component="h1">
            {t('Ciao')} {this.props.globalProvider.state.user.name}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {t('Benvenuto nella tua dashboard.')}
          </Typography>
        </CardComponent>

        <CardComponent noContent>
          <CardHeader
            avatar={
              <div>
                <MonetizationOnIcon style={{ fontSize: 60, color: config.colors.secondary }} />
              </div>
            }
            title={t('Credito')}
            subheader={t('Il tuo credito disponibile è:')}
          />
          <CardContent>
            <Typography gutterBottom variant="h2" component="h2" style={{ fontWeight: 'bold', color: config.colors.primary, textAlign: 'center', marginBottom: 0 }}>
              {printMoney(this.props.globalProvider.state.user.money)}
            </Typography>
          </CardContent>
          <CardActions style={{ justifyContent: 'flex-end' }}>
            <Button color="primary" onClick={this.goToPaymentsNew}>
              {t('Ricarica il tuo conto')}
            </Button>
          </CardActions>
        </CardComponent>

        {window.chromeWebAppPrompt && (
          <CardComponent noContent>
            <CardHeader
              avatar={
                <div>
                  <SmartphoneIcon style={{ fontSize: 60, color: config.colors.secondary }} />
                </div>
              }
              title={t("Installa l'App sul tuo telefono")}
              subheader={t("Assicurati di tenere l'App di AquaPass sempre a portata di mano!")}
            />
            <CardActions style={{ justifyContent: 'flex-end' }}>
              <Button color="primary" onClick={this.chromeWebAppPromptRun}>
                {t("Installa l'app sul telefono")}
              </Button>
            </CardActions>
          </CardComponent>
        )}
      </>
    )
  }
}

export default withGlobalContext(DashboardView)