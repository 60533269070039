import React from 'react'
import { Typography, FormControl, Input, InputLabel, Button, Snackbar } from '@material-ui/core'
import CardComponent from '../components/CardComponent'
import { withGlobalContext } from '../contexts/GlobalContext'
import { onChangeFormInput } from '../libs/helpers'
import { login } from '../libs/apis'

class LoginView extends React.Component {

  constructor(props) {
    super(props)

    this.state = { _form: {}, _formSubmitting: false, _formMessage: false }
  }

  resetFormMessage = () => {
    this.setState({ _formMessage: false })
  }

  goToSignup = () => {
    this.props.globalProvider.actions.setView('Signup')
  }

  goToRestorePassword = () => {
    this.props.globalProvider.actions.setView('RestorePassword')
  }

  onChangeFormInput = (e) => {
    onChangeFormInput(e, this)
  }

  onSubmitForm = (e) => {
    e.preventDefault()
    
    e.preventDefault()
    
    this.setState({ _formSubmitting: true }, () => {
      login(Object.assign({ language: 'it' }, this.state._form)).then((response) => {
        this.setState({ _formSubmitting: false })
        if (response === 'NotFound') {
          this.setState({ _formMessage: this.props.globalProvider.t('Email o password non validi') })
        } else if (response.auth) {
          this.props.globalProvider.actions.login(response, () => {
            this.props.globalProvider.actions.setView('Dashboard')
          })
        }
      })
    })
  }
  
  render() {
    const { t } = this.props.globalProvider

    return (
      <>
        <CardComponent>
          <Typography gutterBottom variant="h5" component="h2">
            {t('Login')}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {t('Inserisci le tue credenziali per accedere. Se non ricordi la tua password puoi sempre')} <a href="#" onClick={this.goToRestorePassword}>{t('recuperare le tue credenziali')}</a>.
          </Typography>

          <form style={{ marginTop: 50 }} onSubmit={this.onSubmitForm}>
            <FormControl fullWidth>
              <InputLabel htmlFor="emailInput">{t('Indirizzo email')}</InputLabel>
              <Input id="emailInput" name="email" type="email" value={this.state._form.email} onChange={this.onChangeFormInput} required />
            </FormControl>

            <FormControl fullWidth style={{ marginTop: 20 }}>
              <InputLabel htmlFor="passwordInput">{t('Password')}</InputLabel>
              <Input id="passwordInput" name="password" type="password" value={this.state._form.password} onChange={this.onChangeFormInput} required />
            </FormControl>

            <Button variant="contained" color="primary" style={{ marginTop: 30 }} type="submit" fullWidth>
              {t('Conferma')}
            </Button>
          </form>
        </CardComponent>

        <Typography variant="caption" component="div" paragraph style={{ marginTop: 30, textAlign: 'center' }}>
          {t('Oppure')}
        </Typography>

        <Button variant="outlined" color="primary" onClick={this.goToSignup} disabled={this.state._formSubmitting} fullWidth>
          {t('Crea un account')}
        </Button>

        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={!!this.state._formMessage}
          autoHideDuration={6000}
          onClose={this.resetFormMessage}
          message={this.state._formMessage}
        />
      </>
    )
  }
}

export default withGlobalContext(LoginView)