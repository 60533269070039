import React from 'react'
import { createMuiTheme } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'
import { GlobalProvider, GlobalContext } from './contexts/GlobalContext'
import LayoutComponent from './components/LayoutComponent'
import WelcomeView from './views/WelcomeView'
import LoginView from './views/LoginView'
import SignupView from './views/SignupView'
import RestorePasswordView from './views/RestorePasswordView'
import DashboardView from './views/DashboardView'
import BottlesView from './views/BottlesView'
import BottlesNewView from './views/BottlesNewView'
import ErogatorsView from './views/ErogatorsView'
import ProfileView from './views/ProfileView'
import PaymentsNewView from './views/PaymentsNewView'
import NotConnectedView from './views/NotConnectedView'
import SettingsView from './views/SettingsView'
import config from './config'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: config.colors.primary,
    },
    secondary: {
      main: config.colors.secondary,
    },
  },
})

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <GlobalProvider>
          <GlobalContext.Consumer>
            {(globalProvider) => {
              let CurrentView = null
              if (config.views[globalProvider.state.view] && config.views[globalProvider.state.view].view === 'WelcomeView') CurrentView = WelcomeView
              if (config.views[globalProvider.state.view] && config.views[globalProvider.state.view].view === 'LoginView') CurrentView = LoginView
              if (config.views[globalProvider.state.view] && config.views[globalProvider.state.view].view === 'RestorePasswordView') CurrentView = RestorePasswordView
              if (config.views[globalProvider.state.view] && config.views[globalProvider.state.view].view === 'SignupView') CurrentView = SignupView
              if (config.views[globalProvider.state.view] && config.views[globalProvider.state.view].view === 'DashboardView') CurrentView = DashboardView
              if (config.views[globalProvider.state.view] && config.views[globalProvider.state.view].view === 'BottlesView') CurrentView = BottlesView
              if (config.views[globalProvider.state.view] && config.views[globalProvider.state.view].view === 'ErogatorsView') CurrentView = ErogatorsView
              if (config.views[globalProvider.state.view] && config.views[globalProvider.state.view].view === 'BottlesNewView') CurrentView = BottlesNewView
              if (config.views[globalProvider.state.view] && config.views[globalProvider.state.view].view === 'ProfileView') CurrentView = ProfileView
              if (config.views[globalProvider.state.view] && config.views[globalProvider.state.view].view === 'PaymentsNewView') CurrentView = PaymentsNewView
              if (config.views[globalProvider.state.view] && config.views[globalProvider.state.view].view === 'SettingsView') CurrentView = SettingsView

              let fullScreen = config.views[globalProvider.state.view] && config.views[globalProvider.state.view].fullScreen

              if (!navigator.onLine) {
                CurrentView = NotConnectedView
                fullScreen = false
              }

              return (
                <LayoutComponent fullScreen={fullScreen}>
                  {CurrentView ? <CurrentView /> : null}
                </LayoutComponent>
              )
            }}
          </GlobalContext.Consumer>
        </GlobalProvider>
      </ThemeProvider>
    </div>
  )
}

export default App