import React from 'react'
import { Typography, FormControl, Input, InputLabel, Button, Snackbar } from '@material-ui/core'
import CardComponent from '../components/CardComponent'
import { withGlobalContext } from '../contexts/GlobalContext'
import { onChangeFormInput } from '../libs/helpers'
import { restoreUserPassword } from '../libs/apis'

class RestorePasswordView extends React.Component {

  constructor(props) {
    super(props)

    this.state = { _form: {}, _formSubmitting: false, _formMessage: false, showThanks: false }
  }

  resetFormMessage = () => {
    this.setState({ _formMessage: false })
  }

  goToWelcome = () => {
    this.props.globalProvider.actions.setView('Welcome')
  }

  goToLogin = () => {
    this.props.globalProvider.actions.setView('Login')
  }

  onChangeFormInput = (e) => {
    onChangeFormInput(e, this)
  }

  onSubmitForm = (e) => {
    e.preventDefault()

    this.setState({ _formSubmitting: true }, () => {
      restoreUserPassword(this.state._form).then((response) => {
        this.setState({ _formSubmitting: false })
        if (response === 'NotFound') {
          this.setState({ _formMessage: this.props.globalProvider.t('Email o anno non valido') })
        } else { // NOTE: Valutare se gestire un po meglio il caso positivo
          this.setState({ showThanks: true })
        }
      })
    })
  }
  
  render() {
    const { t } = this.props.globalProvider

    if (this.state.showThanks) {
      return (
        <>
          <CardComponent>
            <Typography gutterBottom variant="h5" component="h2">
              {t('Recupero credenziali')}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {t('A breve riceverai una e-mail con la nuova password temporanea che puoi utilizzare per accedere con il tuo account.')}
            </Typography>
          </CardComponent>

          <Button variant="contained" color="primary" onClick={this.goToLogin} disabled={this.state._formSubmitting} fullWidth>
            {t('Vai al login')}
          </Button>

          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            open={!!this.state._formMessage}
            autoHideDuration={6000}
            onClose={this.resetFormMessage}
            message={this.state._formMessage}
          />
        </>
      )
    }

    return (
      <>
        <CardComponent>
          <Typography gutterBottom variant="h5" component="h2">
            {t('Recupero credenziali')}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {t('Compila i campi richiesti per procedere al recupero delle tue credenziali di accesso.')}
          </Typography>

          <form style={{ marginTop: 50 }} onSubmit={this.onSubmitForm}>
            <FormControl fullWidth>
              <InputLabel htmlFor="emailInput">{t('Indirizzo email')}</InputLabel>
              <Input id="emailInput" name="email" type="email" value={this.state._form.email} onChange={this.onChangeFormInput} required />
            </FormControl>

            <FormControl fullWidth style={{ marginTop: 20 }}>
              <InputLabel htmlFor="yearInput">{t('Anno di nascita')}</InputLabel>
              <Input id="yearInput" name="year" type="number" value={this.state._form.year} onChange={this.onChangeFormInput} min="1920" max="2020" required />
            </FormControl>

            <Button variant="contained" color="primary" style={{ marginTop: 30 }} type="submit" fullWidth>
              {t('Conferma')}
            </Button>
          </form>
        </CardComponent>

        <Typography variant="caption" component="div" paragraph style={{ marginTop: 30, textAlign: 'center' }}>
          {t('Oppure')}
        </Typography>

        <Button variant="outlined" color="primary" onClick={this.goToWelcome} disabled={this.state._formSubmitting} fullWidth>
          {t('Torna alla home')}
        </Button>

        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={!!this.state._formMessage}
          autoHideDuration={6000}
          onClose={this.resetFormMessage}
          message={this.state._formMessage}
        />
      </>
    )
  }
}

export default withGlobalContext(RestorePasswordView)