import React from 'react'
import { storeGet, storeSet, storeReset } from '../libs/store'

import it from '../locales/it.json'
import en from '../locales/en.json'
import de from '../locales/de.json'
import es from '../locales/es.json'
import fr from '../locales/fr.json'

const locales = {
  it, en, de, es, fr
}

/**
 * Gestisce lo stato principale e generico dell'app.
 */

// Define Global Context.
export const GlobalContext = React.createContext()

// Define Global Provider.
export class GlobalProvider extends React.Component {

  constructor(props) {
    super(props)

    this.initialState = {
      loading: true,
      view: null,
      drawer: false,
      user: null,
      token: null,
      locale: null
    }

    this.state = Object.assign({}, this.initialState)
  }

  componentDidMount() {
    const token = storeGet('token')
    const locale = storeGet('locale') || 'it'

    const userString = storeGet('user')
    const user = userString ? JSON.parse(userString) : null

    this.setState({
      loading: false,
      view: user && token ? 'Dashboard' : 'Welcome',
      drawer: false,
      user, token, locale
    })
  }

  // Actions
  // ----------------------------------------------------------------------------------------

  setView = (v) => {
    this.setState({ view: v })
  }

  openDrawer = () => {
    this.setState({ drawer: true })
  }

  closeDrawer = () => {
    this.setState({ drawer: false })
  }

  toggleDrawer = () => {
    this.state.drawer ? this.closeDrawer() : this.openDrawer()
  }

  login = (user, callback = () => {}) => {
    storeSet('token', user.auth)
    storeSet('user', JSON.stringify(user))
    this.setState({ token: user.auth, user }, () => {
      callback()
    })
  }

  logout = (callback = () => {}) => {
    storeReset()
    this.setState({ token: null, user: null }, () => {
      callback()
    })
  }

  setUserMoney = (money, callback = () => {}) => {
    this.setState({ user: Object.assign({}, this.state.user, { money: money }) }, () => {
      callback()
    })
  }

  setLocale = (locale, callback = () => {}) => {
    storeSet('locale', locale)
    this.setState({ locale }, () => {
      callback()
    })
  }

  t = (key) => {
    const lang = locales[this.state.locale] ? this.state.locale : 'it'
    return locales[lang][key] || key
  }

  // ----------------------------------------------------------------------------------------

  getValues = () => {
    return {
      state: this.state,
      t: this.t,
      actions: {
        setView: this.setView,
        openDrawer: this.openDrawer,
        closeDrawer: this.closeDrawer,
        toggleDrawer: this.toggleDrawer,
        login: this.login,
        logout: this.logout,
        setUserMoney: this.setUserMoney,
        setLocale: this.setLocale
      }
    }
  }

  render () {
    return (
      <GlobalContext.Provider value={this.getValues()}>
          {this.props.children}
      </GlobalContext.Provider>
    )
  }
}

// Define withGlobalContext function.
export function withGlobalContext(Component) {
  class ComponentWithContext extends React.Component {
    render () {
      return (
        <GlobalContext.Consumer>
          {(value) => <Component {...this.props} globalProvider={value} />}
        </GlobalContext.Consumer>
      )
    }
  }

  return ComponentWithContext
}
