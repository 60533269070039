import React from 'react'
import ReactDOM from 'react-dom'
import mapboxgl from 'mapbox-gl'
import App from './App'
import * as serviceWorker from './serviceWorker'

import 'mapbox-gl/dist/mapbox-gl.css'
import './style.css'

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register()

// Control Chrome web app prompt
window.addEventListener('beforeinstallprompt', (e) => {
  // Prevent Chrome 67 and earlier from automatically showing the prompt
  e.preventDefault();
  // Stash the event so it can be triggered later.
  window.chromeWebAppPrompt = e;
});

// Initialize MapBox
mapboxgl.accessToken = 'pk.eyJ1IjoiYXF1YXBhc3MiLCJhIjoiY2tkb2p6dHlrMXNsNDMwcm8wYjlxMXE3biJ9.b5AwKTk9mLz-jP1B-FW3Wg'