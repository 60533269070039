import React from 'react'
import { Typography} from '@material-ui/core'
import QrReader from 'react-qr-reader'
import CardComponent from '../components/CardComponent'
import { withGlobalContext } from '../contexts/GlobalContext'
import { notifyBottleInitialization } from '../libs/apis'

class BottlesNewView extends React.Component {

  onScanError = (e) => {
    console.log(e)
  }

  onScanCompleted = (code) => {
    if (!code) return

    notifyBottleInitialization({ user_id: this.props.globalProvider.state.user.user_id, bottle_id: code }).then((response) => {
      this.goToBottles()
    }).catch((err) => {
      alert('Codice non valido')
    })
  }

  goToBottles = () => {
    this.props.globalProvider.actions.setView('Bottles')
  }

  render() {
    const { t } = this.props.globalProvider

    if (!this.props.globalProvider.state.token) return null

    return (
      <>
        <CardComponent>
          <Typography gutterBottom variant="h5" component="h1">
            {t('Associa nuova bottiglia')}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {t("Inquadra il QR Code presente nell'etichetta della bottiglia per aggiungerla al tuo profilo.")}
          </Typography>
        </CardComponent>

        <CardComponent>
          <QrReader
            delay={300}
            onError={this.onScanError}
            onScan={this.onScanCompleted}
            style={{ width: '100%' }}
          />
        </CardComponent>
      </>
    )
  }
}

export default withGlobalContext(BottlesNewView)