export function onChangeFormInput(event, component, formName = '_form') {
  const form = component.state[formName] ? Object.assign({}, component.state[formName]) : {}
  form[event.target.name] = event.target.value
  const newState = {}; newState[formName] = form
  component.setState(newState)
}

export function printMoney(value) {
  if (!(typeof value === 'number')) value = parseFloat(value)
  return value.toFixed(2) + '€'
}