import React from 'react'
import { MobileStepper, CircularProgress, Typography, FormControl, Input, InputLabel, Select, MenuItem, Button, TextField } from '@material-ui/core'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import CardComponent from '../components/CardComponent'
import PaypalButtonsComponent from '../components/PaypalButtonsComponent'
import { withGlobalContext } from '../contexts/GlobalContext'
import { onChangeFormInput } from '../libs/helpers'
import { getPayPaypalEnv, getPayPaypalClient, addPayPaypalProof, getPayPaypalPendingProofs } from '../libs/apis'
import config from '../config'

class SignupView extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      step: 0,
      paypalEnv: null,
      paypalClientId: null,
      paymentLoading: false,
      _form: { option: '' },
      _formOptions: [{ id: 5, name: 'Ricarica 5€' }, { id: 10, name: 'Ricarica 10€' }, { id: 15, name: 'Ricarica 15€' }]
    }
    this.maxSteps = 2
  }

  componentDidMount() {
    // getPayPaypalPendingProofs({ user_id: this.props.globalProvider.state.user.user_id }).then((response) => {
    //   console.log(response)
    // })

    // NOTE: Campi del server non usati; env arriva dal server, client id è impostato in config.
    // getPayPaypalEnv().then((responseEnv) => {
    //   getPayPaypalClient().then((responseClient) => {
    //     this.setState({
    //       loading: false,
    //       paypalEnv: responseEnv.environment,
    //       paypalClientId: responseClient.clientId
    //     })
    //   })
    // })
  }

  goToNextStep = () => {
    if (!this.state._form.option) {
      this.setState({ _formMessage: this.props.globalProvider.t('Compila tutti i campi per proseguire') })
      return
    }

    this.setState({ step: this.state.step + 1 })
  }

  goToPrevStep = () => {
    this.setState({ step: this.state.step - 1 })
  }

  goToDashboard = () => {
    this.props.globalProvider.actions.setView('Dashboard')
  }

  onChangeFormInput = (e) => {
    onChangeFormInput(e, this)
  }

  onPaymentStart = () => {
    this.setState({ paymentLoading: true })
  }

  onPaymentError = () => {
    this.setState({ paymentLoading: false })
    alert(this.props.globalProvider.t('Si è verificato un errore durante il tentativo di pagamento'))
    this.props.globalProvider.actions.setView('Dashboard')
  }

  onPaymentCompleted = (details) => {
    const paymentId = details.id

    console.log('dati pagamento ricevuti da paypal', details)

    addPayPaypalProof({ user_id: this.props.globalProvider.state.user.user_id, proof: paymentId }).then((response) => {
      if (response.result === 'accepted') {
        this.goToDashboard()
      }

      this.setState({ paymentLoading: false })
    })
  }

  render() {
    const { t } = this.props.globalProvider

    if (!this.props.globalProvider.state.token) return null

    if (this.state.loading) {
      return (
        <div style={{ width: '100%', height: config.viewHeightWithoutTopBottomBar, textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress color="inherit" />
        </div>
      )
    }

    return (
      <>
        <CardComponent>
          <Typography gutterBottom variant="h5" component="h2">
            {t('Ricarica il tuo conto')}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {t('Completa il processo di pagamento per ricaricare il tuo conto Aquapass.')}
          </Typography>

          <form style={{ marginTop: 50 }} onSubmit={(e) => e.preventDefault()}>
            {this.state.step === 0 && (
              <>
                <FormControl fullWidth style={{ marginTop: 20 }}>
                  <InputLabel id="optionInputLabel" htmlFor="optionInput" shrink>{t('Di quanto vuoi ricaricare il conto?')}</InputLabel>
                  <Select
                    labelId="optionInputLabel"
                    id="optionInput"
                    name="option"
                    value={this.state._form.option}
                    onChange={this.onChangeFormInput}
                    required
                  >
                    {this.state._formOptions.map((g) => {
                      return <MenuItem value={g.id} key={g.id}>{g.name}</MenuItem>
                    })}
                  </Select>
                </FormControl>
              </>
            )}
            {this.state.step === 1 && (
              <div style={{ position: 'relative' }}>
                <PaypalButtonsComponent
                  value={this.state._form.option}
                  name={t('Ricarica conto Aquapass')}
                  env={this.state.paypalEnv} // NOTE: Per ora non utilizzato
                  clientId={this.state.paypalClientId} // NOTE: Per ora non utilizzato
                  onPaymentError={this.onPaymentError}
                  onPaymentCompleted={this.onPaymentCompleted}
                  onPaymentStart={this.onPaymentStart}
                />
                {this.state.paymentLoading && (
                  <div style={{ width: '100%', height: '100%', textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'absolute', top: 0, left: 0, backgroundColor: 'rgba(255, 255, 255, 0.7)', zIndex: 999 }}>
                    <CircularProgress color="inherit" />
                  </div>
                )}
              </div>
            )}
          </form>

          <MobileStepper
            steps={this.maxSteps}
            style={{ marginTop: 30 }}
            position="static"
            activeStep={this.state.step}
            nextButton={
              <Button size="small" onClick={this.goToNextStep} disabled={this.state.step === this.maxSteps - 1}>
                {t('Avanti')}
                <KeyboardArrowRight />
              </Button>
            }
            backButton={
              <Button size="small" onClick={this.goToPrevStep} disabled={this.state.step === 0}>
                <KeyboardArrowLeft />
                {t('Indietro')}
              </Button>
            }
          />
        </CardComponent>
      </>
    )
  }
}

export default withGlobalContext(SignupView)