import React from 'react'
import PropTypes from 'prop-types'
import { Card, CardContent } from '@material-ui/core'

function Div(props) { return <div>{props.children}</div> }

class CardComponent extends React.Component {
  render() {
    const Content = this.props.noContent ? Div : CardContent

    return (
      <Card elevation={0.2} style={{ marginBottom: 10 }}>
        {this.props.header ? this.props.header : null}
        {this.props.children ? (
          <Content>
            {this.props.children}
          </Content>
        ) : null}
      </Card>
    )
  }
}

CardComponent.propTypes = {
  noContent: PropTypes.bool,
  style: PropTypes.object,
  header: PropTypes.func
}

export default CardComponent