import React from 'react'
import { Typography, Button } from '@material-ui/core'
import { withGlobalContext } from '../contexts/GlobalContext'

import Logo from '../assets/logo.svg'

class WelcomeView extends React.Component {

  goToLogin = () => {
    this.props.globalProvider.actions.setView('Login')
  }

  goToSignup = () => {
    this.props.globalProvider.actions.setView('Signup')
  }

  render() {
    const { t } = this.props.globalProvider

    return (
      <div style={{ textAlign: 'center', paddingTop: 50 }}>
        <img src={Logo} style={{ width: '70%', marginBottom: 50 }} alt="Aquapass" />

        <Typography variant="h4" component="h1">
          {t('Benvenuto')}
        </Typography>

        <div style={{ marginTop: 50 }}>
          <Button variant="contained" color="primary" onClick={this.goToLogin} fullWidth>
            {t('Accedi')}
          </Button>

          <Typography variant="caption" component="div" paragraph style={{ marginTop: 30 }}>
            {t('Oppure')}
          </Typography>

          <Button variant="outlined" color="primary" onClick={this.goToSignup} fullWidth>
            {t('Crea un account')}
          </Button>
        </div>
      </div>
    )
  }
}

export default withGlobalContext(WelcomeView)