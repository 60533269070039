import React from 'react'
import { Typography, CircularProgress, Button, CardHeader, IconButton, Menu, MenuItem, ListItemIcon } from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import DeleteIcon from '@material-ui/icons/Delete'
import LoopIcon from '@material-ui/icons/Loop'
import CardComponent from '../components/CardComponent'
import { withGlobalContext } from '../contexts/GlobalContext'
import { getUserBottles, getBottleSizes, getLiquidTypes, voidBottle, setBottle } from '../libs/apis'
import config from '../config'

class BottlesView extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      bottles: [],
      menuEl: null,
      menuBottle: null
    }
  }

  componentDidMount() {
    getBottleSizes().then((responseSizes) => {
      this.responseSizes = responseSizes
      getLiquidTypes().then((responseTypes) => {
        this.responseTypes = responseTypes

        this.loadBottles()
      })
    })
  }

  goToBottlesNew = () => {
    this.props.globalProvider.actions.setView('BottlesNew')
  }

  deleteBottle = (bottle) => {
    const confirmation = window.confirm(this.props.globalProvider.t('Sei sicuro di voler rimuovere la bottiglia dal tuo account?'))
    if (!confirmation) return
    voidBottle(this.props.globalProvider.state.user.user_id, bottle.bottle_uid).then((response) => {
      this.loadBottles()
      this.closeBottleMenu()
    })
  }

  changeBottleType = (bottle) => {
    setBottle(this.props.globalProvider.state.user.user_id, bottle.bottle_uid, bottle.content == '4' ? '2' : '4').then((response) => {
      this.loadBottles()
      this.closeBottleMenu()
    })
  }

  loadBottles = () => {
    getUserBottles(this.props.globalProvider.state.user.user_id).then((response) => {
      this.setState({loading: false, bottles: response.bottles.map((b) => {
        const type = this.responseTypes.data.filter((d) => d.id === b.content)[0] || {}
        const size = this.responseSizes.data.filter((d) => d.id === b.type)[0] || {}
        b.the_size = size
        b.the_type = type
        return b
      })})
    })
  }

  closeBottleMenu = () => {
    this.setState({
      menuEl: null,
      menuBottle: null
    })
  }

  openBottleMenu = (el, bottle) => {
    this.setState({
      menuEl: el,
      menuBottle: bottle
    })
  }

  render() {
    const { t } = this.props.globalProvider

    if (!this.props.globalProvider.state.token) return null

    if (this.state.loading) {
      return (
        <div style={{ width: '100%', height: config.viewHeightWithoutTopBottomBar, textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress color="inherit" />
        </div>
      )
    }

    return (
      <>
        <CardComponent>
          <Typography gutterBottom variant="h5" component="h1">
            {t('Le mie bottiglie')}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {t('In questa sezione sono presenti tutte le bottiglie associate al tuo profilo.')}
          </Typography>
        </CardComponent>

        {this.state.bottles.map((bottle) => {
          return (
            <CardComponent
              key={bottle.bottle_id}
              header={(
                <CardHeader
                  action={
                    <IconButton aria-label="settings" onClick={(e) => this.openBottleMenu(e.currentTarget, bottle)}>
                      <MoreVertIcon />
                    </IconButton>
                  }
                  title={bottle.the_type.name}
                  subheader={`${bottle.the_size.name} - ${bottle.the_size.capacity}cl`}

                />
              )}
            >
            </CardComponent>
          )
        })}

        <Menu
          anchorEl={this.state.menuEl}
          open={Boolean(this.state.menuEl)}
          onClose={this.closeBottleMenu}
        >
          <MenuItem onClick={() => this.changeBottleType(this.state.menuBottle)}>
            {/* <ListItemIcon>
              <LoopIcon fontSize="small" />
            </ListItemIcon> */}
            <Typography variant="inherit">{t('Cambia contenuto')}</Typography>
          </MenuItem>
          <MenuItem onClick={() => this.deleteBottle(this.state.menuBottle)}>
            {/* <ListItemIcon>
              <DeleteIcon fontSize="small" />
            </ListItemIcon> */}
            <Typography variant="inherit">{t('Elimina')}</Typography>
          </MenuItem>
        </Menu>

        <Button variant="contained" color="primary" onClick={this.goToBottlesNew} fullWidth>
          {t('Associa nuova bottiglia')}
        </Button>
      </>
    )
  }
}

export default withGlobalContext(BottlesView)