import React from 'react'
import { Typography, CircularProgress } from '@material-ui/core'
import mapboxgl from 'mapbox-gl'
import CardComponent from '../components/CardComponent'
import { withGlobalContext } from '../contexts/GlobalContext'
import { getErogPos } from '../libs/apis'
import config from '../config'

class ErogatorsView extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      showUnable: false
    }

    this._mapboxMap = null
    this._mapboxMapMarkers = {}
  }

  componentDidMount() {
    // run geolocalization service
    if (navigator.geolocation) {
      this.positionInterval = setInterval(this.geocode, 30000)
      this.geocode()
    } else {
      this.setState({ loading: false, showUnable: true })
    }
  }

  componentWillUnmount() {
    if (this.positionInterval) clearInterval(this.positionInterval)
  }

  geocode = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      const lat = position.coords.latitude
      const lng = position.coords.longitude
      const DIFF = 100

      // update state
      this.setState({ loading: false }, () => {

        // render map
        if (!this._mapboxMap) {
          this._mapboxMap = new mapboxgl.Map({
            container: this.mapContainer,
            style: 'mapbox://styles/mapbox/streets-v11',
            center: [lng, lat],
            zoom: 8
          })
        }

        // get geolocated erogators
        getErogPos({ latMin: lat - DIFF, lonMin: lng - DIFF, latMax: lat + DIFF, lonMax: lng + DIFF}).then((response) => {
          if (response.data && response.data.length) {
            response.data.forEach((erog) => {
              if (this._mapboxMapMarkers[erog.id]) return

              const popup = new mapboxgl.Popup({ offset: 25 })
              popup.setHTML(`<a class='MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary' style='margin-top: 5px;' href='http://www.google.com/maps/place/${erog.lat},${erog.lon}' target='_blank'>Ottieni indicazioni</a>`)

              this._mapboxMapMarkers[erog.id] = new mapboxgl.Marker()
              this._mapboxMapMarkers[erog.id].setLngLat([parseFloat(erog.lon), parseFloat(erog.lat)])
              this._mapboxMapMarkers[erog.id].setPopup(popup)
              this._mapboxMapMarkers[erog.id].addTo(this._mapboxMap)
            })
          } else {
            // TODO: Mostrare avviso di assenza ergatori
          }
        })
      })
    }, (err) => {
      console.log(err)
    })
  }

  render() {
    const { t } = this.props.globalProvider

    if (!this.props.globalProvider.state.token) return null

    if (this.state.loading) {
      return (
        <div style={{ width: '100%', height: config.viewHeightWithoutTopBottomBar, textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress color="inherit" />
        </div>
      )
    }

    if (this.state.showUnable) {
      return (
        <>
          <CardComponent>
            <Typography gutterBottom variant="h5" component="h2">
              {t('Geolocalizzazione non disponibile')}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {t('Il tuo dispositivo non supporta la geolocalizzazione.')}
            </Typography>
          </CardComponent>
        </>
      )
    }

    return (
      <div>
        <div ref={el => this.mapContainer = el} style={{ width: '100%', height: '100vh' }} className="mapBox" />
      </div>
    )
  }
}

export default withGlobalContext(ErogatorsView)