import React from 'react'
import { Typography, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core'
import CardComponent from '../components/CardComponent'
import { withGlobalContext } from '../contexts/GlobalContext'

class SettingsView extends React.Component {

  onChangeLocale = (e) => {
    this.props.globalProvider.actions.setLocale(e.target.value)
  }

  render() {
    const { t } = this.props.globalProvider

    return (
      <>
        <CardComponent>
          <Typography gutterBottom variant="h5" component="h2">
            {t('Impostazioni')}
          </Typography>

          <FormControl fullWidth style={{ marginTop: 20 }}>
            <InputLabel id="langInputLabel" htmlFor="langInput" shrink>{t('Lingua')}</InputLabel>
            <Select
              labelId="langInputLabel"
              id="langInput"
              name="gender"
              value={this.props.globalProvider.state.locale}
              onChange={this.onChangeLocale}
              required
            >
              <MenuItem value={'it'}>Italian</MenuItem>
              <MenuItem value={'en'}>English</MenuItem>
              <MenuItem value={'de'}>German</MenuItem>
              <MenuItem value={'fr'}>French</MenuItem>
              <MenuItem value={'es'}>Spanish</MenuItem>
            </Select>
          </FormControl>
        </CardComponent>
      </>
    )
  }
}

export default withGlobalContext(SettingsView)