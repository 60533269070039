import React from 'react'
import { Typography } from '@material-ui/core'
import CardComponent from '../components/CardComponent'
import { withGlobalContext } from '../contexts/GlobalContext'

class NotConnectedView extends React.Component {

  render() {
    const { t } = this.props.globalProvider

    return (
      <>
        <CardComponent>
          <Typography gutterBottom variant="h5" component="h2">
            {t('Rete non disponibile')}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {t("Per utilizzare l'applicazione devi essere connesso a internet.")}
          </Typography>
        </CardComponent>
      </>
    )
  }
}

export default withGlobalContext(NotConnectedView)