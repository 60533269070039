import React from 'react'
import { AppBar, Toolbar, Typography, IconButton, Drawer, List, ListItem, ListItemIcon, ListItemText, Backdrop, CircularProgress, Container, Divider, BottomNavigation, BottomNavigationAction } from '@material-ui/core'
import HomeIcon from '@material-ui/icons/Home'
import SettingsIcon from '@material-ui/icons/Settings'
import MenuIcon from '@material-ui/icons/Menu'
import LocalDrinkIcon from '@material-ui/icons/LocalDrink'
import PersonIcon from '@material-ui/icons/Person'
import MapIcon from '@material-ui/icons/Map'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import { withGlobalContext } from '../contexts/GlobalContext'
import config from '../config'

import Logo from '../assets/logo.svg'
import packageJson from '../../package.json'

function Div(props) { return <div>{props.children}</div> }

class LayoutComponent extends React.Component {
  
  logout = () => {
    this.props.globalProvider.actions.logout(() => {
      this.props.globalProvider.actions.closeDrawer()
      this.props.globalProvider.actions.setView('Welcome')
    })
  }

  goToWelcome = () => {
    this.props.globalProvider.actions.closeDrawer()
    this.props.globalProvider.actions.setView('Welcome')
  }

  goToDashboard = () => {
    this.props.globalProvider.actions.closeDrawer()
    this.props.globalProvider.actions.setView('Dashboard')
  }

  goToBottles = () => {
    this.props.globalProvider.actions.closeDrawer()
    this.props.globalProvider.actions.setView('Bottles')
  }

  goToErogators = () => {
    this.props.globalProvider.actions.closeDrawer()
    this.props.globalProvider.actions.setView('Erogators')
  }

  goToProfile = () => {
    this.props.globalProvider.actions.closeDrawer()
    this.props.globalProvider.actions.setView('Profile')
  }

  goToSettings = () => {
    this.props.globalProvider.actions.closeDrawer()
    this.props.globalProvider.actions.setView('Settings')
  }

  onChangeBottomBar = (e, index) => {
    const viewName = Object.keys(config.views).filter((n) => {
      const v = config.views[n]
      return v.bottomBarIndex === index
    })[0]
    if (!viewName) return
    this.props.globalProvider.actions.setView(viewName)
  }

  renderDrawer() {
    const { t } = this.props.globalProvider

    return (
      <List style={{ minWidth: 250 }}>
        <ListItem style={{ textAlign: 'center' }}>
          <img src={Logo} style={{ width: 200, marginBottom: 10 }} alt="Aquapass" />
        </ListItem>
        <Divider />
        {!this.props.globalProvider.state.token && (
          <ListItem button key={'Home'} onClick={this.goToWelcome} >
            <ListItemIcon><HomeIcon /></ListItemIcon>
            <ListItemText primary={t('Home')} />
          </ListItem>
        )}
        {this.props.globalProvider.state.token && (
          <ListItem button key={'Dashboard'} onClick={this.goToDashboard} >
            <ListItemIcon><HomeIcon /></ListItemIcon>
            <ListItemText primary={t('Dashboard')} />
          </ListItem>
        )}
        {this.props.globalProvider.state.token && (
          <ListItem button key={'Bottiglie'} onClick={this.goToBottles} >
            <ListItemIcon><LocalDrinkIcon /></ListItemIcon>
            <ListItemText primary={t('Bottiglie')} />
          </ListItem>
        )}
        {this.props.globalProvider.state.token && (
          <ListItem button key={'Erogatori'} onClick={this.goToErogators} >
            <ListItemIcon><MapIcon /></ListItemIcon>
            <ListItemText primary={t('Erogatori')} />
          </ListItem>
        )}
        {this.props.globalProvider.state.token && (
          <ListItem button key={'Profilo'} onClick={this.goToProfile} >
            <ListItemIcon><PersonIcon /></ListItemIcon>
            <ListItemText primary={t('Profilo')} />
          </ListItem>
        )}
        <ListItem button key={'Impostazioni'} onClick={this.goToSettings} >
          <ListItemIcon><SettingsIcon /></ListItemIcon>
          <ListItemText primary={t('Impostazioni')} />
        </ListItem>
        {this.props.globalProvider.state.token && (
          <ListItem button key={'Logout'} onClick={this.logout}>
            <ListItemIcon><ExitToAppIcon /></ListItemIcon>
            <ListItemText primary={t('Logout')} />
          </ListItem>
        )}
        <Divider />
        <ListItem key={'Version'}>
          <ListItemText secondary={`version ${packageJson.version}`} />
        </ListItem>
      </List>
    )
  }

  render() {
    const viewConfig = config.views[this.props.globalProvider.state.view]

    const TheContainer = this.props.fullScreen ? Div : Container

    return (
      <>
        <Backdrop open={this.props.globalProvider.state.loading} style={{ color: '#fff', zIndex: 999 }}>
          <CircularProgress color="inherit" />
        </Backdrop>

        <AppBar position="fixed">
          <Toolbar variant="dense">
            {navigator.onLine && (
              <IconButton edge="start" color="inherit" aria-label="menu" onClick={this.props.globalProvider.actions.toggleDrawer}>
                <MenuIcon />
              </IconButton>
            )}
            <Typography variant="h6" color="inherit">
              {viewConfig ? viewConfig.title : ''}
            </Typography>
          </Toolbar>
        </AppBar>

        <TheContainer style={{ paddingBottom: this.props.fullScreen ? 0 : 55, paddingTop: this.props.fullScreen ? 0 : 60 }}>
          {this.props.children}
        </TheContainer>

        {this.props.globalProvider.state.token && navigator.onLine && (
          <BottomNavigation
            value={viewConfig.bottomBarIndex}
            style={{ position: 'fixed', bottom: 0, left: 0, width: '100%' }}
            onChange={this.onChangeBottomBar}
          >
            <BottomNavigationAction label="Bottiglie" icon={<LocalDrinkIcon />} />
            <BottomNavigationAction label="Dashboard" icon={<HomeIcon />} />
            <BottomNavigationAction label="Erogatori" icon={<MapIcon />} />
          </BottomNavigation>
        )}

        {navigator.onLine && (
          <Drawer open={this.props.globalProvider.state.drawer} onClose={this.props.globalProvider.actions.closeDrawer}>
            {this.renderDrawer()}
          </Drawer>
        )}
      </>
    )
  }
}

export default withGlobalContext(LayoutComponent)
