export function storeSet(key, value) {
  return localStorage.setItem(key, value)
}

export function storeGet(key) {
  return localStorage.getItem(key)
}

export function storeReset() {
  return localStorage.clear()
}