export default {
  colors: {
    primary: '#005b99',
    secondary: '#60a097'
  },

  views: {
    'Welcome': {
      title: 'AquaPass',
      view: 'WelcomeView'
    },
    'Login': {
      title: 'AquaPass',
      view: 'LoginView'
    },
    'Signup': {
      title: 'AquaPass',
      view: 'SignupView'
    },
    'RestorePassword': {
      title: 'AquaPass',
      view: 'RestorePasswordView'
    },
    'Dashboard': {
      title: 'AquaPass',
      view: 'DashboardView',
      bottomBarIndex: 1
    },
    'Bottles': {
      title: 'AquaPass',
      view: 'BottlesView',
      bottomBarIndex: 0
    },
    'BottlesNew': {
      title: 'AquaPass',
      view: 'BottlesNewView',
      bottomBarIndex: 0
    },
    'Erogators': {
      title: 'AquaPass',
      view: 'ErogatorsView',
      bottomBarIndex: 2,
      fullScreen: true
    },
    'Profile': {
      title: 'AquaPass',
      view: 'ProfileView'
    },
    'PaymentsNew': {
      title: 'AquaPass',
      view: 'PaymentsNewView'
    },
    'Settings': {
      title: 'AquaPass',
      view: 'SettingsView'
    }
  },

  siteUrl: process.env.PUBLIC_URL,

  proxyUrl: process.env.REACT_APP_PROXY_URL,

  viewHeightWithoutTopBottomBar: 'calc(100vh - 125px)',

  paypalClientId: 'AYY_uOPq-x2OuTfrQ-JAqcFZ9yWHG4kT99RRPbhjlR9Yl8tBUKlVaMwJTkg6hK9YjJJ5CI4MMKjxzqqR'
}
