import React from 'react'
import { Typography, CardHeader, CardContent, Button, FormControl, Input, InputLabel, Select, MenuItem, Snackbar } from '@material-ui/core'
import PersonIcon from '@material-ui/icons/Person'
import LockIcon from '@material-ui/icons/Lock'
import CardComponent from '../components/CardComponent'
import { getGenders, getProfessions, getHobbies, modifyUser } from '../libs/apis'
import { onChangeFormInput } from '../libs/helpers'
import { withGlobalContext } from '../contexts/GlobalContext'
import config from '../config'

class ProfileView extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      _formProfile: {
        name: this.props.globalProvider.state.user.name,
        surname: this.props.globalProvider.state.user.surname,
        gender: this.props.globalProvider.state.user.gender.id,
        occupation: this.props.globalProvider.state.user.occupation.id,
        hobbies: this.props.globalProvider.state.user.hobbies[0].id
      },
      _formPassword: {},
      _formGenders: [],
      _formProfessions: [],
      _formHobbies: [],
      _formSubmitting: false,
      _formMessage: null
    }
  }

  componentDidMount() {
    getGenders().then((response) => {
      this.setState({ _formGenders: response.data })
    }).catch(() => {})
    getProfessions().then((response) => {
      this.setState({ _formProfessions: response.data })
    }).catch(() => {})
    getHobbies().then((response) => {
      this.setState({ _formHobbies: response.data })
    }).catch(() => {})
  }

  resetFormMessage = () => {
    this.setState({ _formMessage: false })
  }

  onChangeFormInputProfile = (e) => {
    onChangeFormInput(e, this, '_formProfile')
  }

  onSubmitFormProfile = (e) => {
    e.preventDefault()

    this.setState({ _formSubmitting: true }, () => {
      modifyUser(Object.assign({ language: 'it', user_id: this.props.globalProvider.state.user.user_id }, this.state._formProfile)).then((response) => {
        this.setState({ _formSubmitting: false })
        if (response.auth) {
          this.props.globalProvider.actions.login(response, () => {
            this.setState({ _formMessage: this.props.globalProvider.t('Profilo aggiornato correttamente') })
          })
        }
      })
    })
  }

  onChangeFormInputPassword = (e) => {
    onChangeFormInput(e, this, '_formPassword')
  }

  onSubmitFormPassword = (e) => {
    e.preventDefault()

    if (this.state._formPassword.password !== this.state._formPassword.passwordConfirmation) {
      this.setState({ _formMessage: this.props.globalProvider.t('Le due password non coincidono') })
      return
    }

    this.setState({ _formSubmitting: true }, () => {
      modifyUser(Object.assign({ language: 'it', user_id: this.props.globalProvider.state.user.user_id }, this.state._formPassword)).then((response) => {
        this.setState({ _formSubmitting: false })
        if (response.auth) {
          this.setState({ _formMessage: this.props.globalProvider.t('Password aggiornata correttamente'), _formPassword: {} })
        }
      })
    })
  }

  render() {
    const { t } = this.props.globalProvider

    if (!this.props.globalProvider.state.token) return null

    return (
      <>
        <CardComponent>
          <Typography gutterBottom variant="h5" component="h1">
            {t('Il mio profilo')}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {t('In questa sezione puoi gestire facilmente le tue informazioni personali.')}
          </Typography>
        </CardComponent>

        <CardComponent noContent>
          <CardHeader
            avatar={
              <div>
                <PersonIcon style={{ fontSize: 60, color: config.colors.secondary }} />
              </div>
            }
            title={t('Profilo')}
            subheader={t('I tuoi dati personali sono:')}
          />
          <CardContent>
            <form onSubmit={this.onSubmitFormProfile}>
              <FormControl fullWidth>
                <InputLabel htmlFor="nameInput" shrink>{t('Nome')}</InputLabel>
                <Input id="nameInput" name="name" onChange={this.onChangeFormInputProfile} value={this.state._formProfile.name} required />
              </FormControl>

              <FormControl fullWidth style={{ marginTop: 20 }}>
                <InputLabel htmlFor="surnameInput" shrink>{t('Cognome')}</InputLabel>
                <Input id="surnameInput" name="surname" onChange={this.onChangeFormInputProfile} value={this.state._formProfile.surname} required />
              </FormControl>

              <FormControl fullWidth style={{ marginTop: 20 }}>
                <InputLabel id="sexInputLabel" htmlFor="sexInput" shrink>{t('Sesso')}</InputLabel>
                <Select
                  labelId="sexInputLabel"
                  id="sexInput"
                  name="gender"
                  value={this.state._formProfile.gender}
                  onChange={this.onChangeFormInputProfile}
                  required
                >
                  {this.state._formGenders.map((g) => {
                    return <MenuItem value={g.id}>{g.name}</MenuItem>
                  })}
                </Select>
              </FormControl>

              <FormControl fullWidth style={{ marginTop: 20 }}>
                <InputLabel id="professionInputLabel" htmlFor="professionInput" shrink>{t('Professione')}</InputLabel>
                <Select
                  labelId="professionInputLabel"
                  id="professionInput"
                  name="occupation"
                  value={this.state._formProfile.occupation}
                  onChange={this.onChangeFormInputProfile}
                  required
                >
                  {this.state._formProfessions.map((g) => {
                    return <MenuItem value={g.id}>{g.name}</MenuItem>
                  })}
                </Select>
              </FormControl>

              <FormControl fullWidth style={{ marginTop: 20 }}>
                <InputLabel id="hobbyInputLabel" htmlFor="hobbyInput" shrink>{t('Hobby')}</InputLabel>
                <Select
                  labelId="hobbyInputLabel"
                  id="hobbyInput"
                  name="hobbies"
                  value={this.state._formProfile.hobbies}
                  onChange={this.onChangeFormInputProfile}
                  required
                >
                  {this.state._formHobbies.map((g) => {
                    return <MenuItem value={g.id}>{g.name}</MenuItem>
                  })}
                </Select>
              </FormControl>

              <Button variant="contained" color="primary" style={{ marginTop: 30 }} type="submit" disabled={this.state._formSubmitting} fullWidth>
                {t('Aggiorna')}
              </Button>
            </form>
          </CardContent>
        </CardComponent>

        <CardComponent noContent>
          <CardHeader
            avatar={
              <div>
                <LockIcon style={{ fontSize: 60, color: config.colors.secondary }} />
              </div>
            }
            title={t('Sicurezza')}
            subheader={t('Per aggiornare la tua password compila i seguenti campi:')}
          />
          <CardContent>
            <form onSubmit={this.onSubmitFormPassword}>
              <FormControl fullWidth style={{ marginTop: 20 }}>
                  <InputLabel htmlFor="passwordInput" shrink>{t('Nuova password')}</InputLabel>
                  <Input id="passwordInput" name="password" type="password" onChange={this.onChangeFormInputPassword} value={this.state._formPassword.password} required />
                </FormControl>

                <FormControl fullWidth style={{ marginTop: 20 }}>
                  <InputLabel htmlFor="passwordConfirmationInput" shrink>{t('Conferma la nuova password')}</InputLabel>
                  <Input id="passwordConfirmationInput" name="passwordConfirmation" type="password" onChange={this.onChangeFormInputPassword} value={this.state._formPassword.passwordConfirmation} required />
                </FormControl>

              <Button variant="contained" color="primary" style={{ marginTop: 30 }} type="submit" disabled={this.state._formSubmitting} fullWidth>
                {t('Aggiorna')}
              </Button>
            </form>
          </CardContent>
        </CardComponent>

        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={!!this.state._formMessage}
          autoHideDuration={6000}
          onClose={this.resetFormMessage}
          message={this.state._formMessage}
        />
      </>
    )
  }
}

export default withGlobalContext(ProfileView)